<template>
  <a-timeline>
    <a-timeline-item>
      <div class="s-timeline">
        <div class="s-timeline-title">提交{{ content.thesisType | setTitle }}</div>
        <div class="s-timeline-i">
          <span class="label">{{ content.thesisType | setTitle }}附件：</span>
          <a target="_blank" :href="content.filePath" class="value">{{ content.thesisTitle }}</a>
          <a style="margin-left: 10px" target="_blank" :href="getFileUrl(content.filePath)" class="value"><a-icon type="file-text" /></a>
        </div>
        <div class="s-timeline-i">
          <span class="label">提交时间：</span><span class="value">{{ content.createTime }}</span>
        </div>
        <div class="s-timeline-i">
          <span class="label">学员签名：</span
          ><span class="value"><img width="150" :src="content.studentAutographImg" alt=""/></span>
        </div>
      </div>
    </a-timeline-item>
    <a-timeline-item>
      <div class="s-timeline">
        <div class="s-timeline-title">{{ content.thesisState | status }}</div>
        <div class="s-timeline-i">
          <span class="label">{{ content.thesisType | setTitle }}指导老师：</span><span class="value">{{ content.realname }}</span>
        </div>
        <div class="s-timeline-i">
          <span class="label">教师评语：</span><span class="value">{{ content.evaluate }}</span>
        </div>
      </div>
    </a-timeline-item>
    <a-timeline-item  v-if="['0','1','2'].some(item => content.thesisState === item )">
      <div class="s-timeline">
        <div class="s-timeline-title">修改并重新提交{{ content.thesisType | setTitle }}</div>
        <div class="s-timeline-i">
          <a-button type="primary" v-if="content.sjIsOnTime === 'Y'" @click="submit">重新提交{{ content.thesisType | setTitle }}</a-button>
        </div>
      </div>
    </a-timeline-item>
  </a-timeline>
</template>

<script>
export default {
  name: 'SubmitTimeLine',
  props: {
    content: { type: Object, default: () => {} }
  },
  components: {},
  data() {
    return {
      gradeInfo: {}
    }
  },
  filters: {
    setTitle(val) {
      let text = '论文'
      switch (val) {
        case '1':
          text = '论文'
          break
        case '2':
          text = '社会实践'
          break
      }
      return text
    },
    status(val) {
      //  1待提交 2待评审 3评审未通过 4评审通过 5已通过，无需提交",
      let text = '--'
      switch (val) {
        case '0':
            text = '请重新提交'
            break
        case '1':
          text = '待提交'
          break
        case '2':
          text = '待评审'
          break
        case '3':
          text = '评审未通过'
          break
        case '4':
          text = '评审通过'
          break
        case '5':
          text = '已通过，无需提交'
          break
      }
      return text
    }
  },
  mounted() {},
  methods: {
    submit() {
      this.$emit('submit')
    },
    getFileUrl(url){
      return 'http://ow365.cn/?info=3&i=25157&furl='+url;
    }
  }
}
</script>

<style lang="scss" scoped>
.s-timeline {
  .s-timeline-title {
    font-weight: bold;
    font-size: 16px;
    .s-timeline-i {
      padding-bottom: 5px;
    }
  }
}
</style>
