<template>
  <div class="mb-10">
    <a-card class="cus-card no-border" :bordered="false">
      <div
        class="un-graduat-wrap pt-20 d-flex col align-center justify-center"
        v-if="!gradeInfo.graduation || gradeInfo.graduation === '未满足'"
      >
        <div class="img-wrap">
          <img src="../../../../assets/img/undraw.png" alt="" />
        </div>
        <p class="text-center fs-14 mt-10">
          您暂未在本次毕业作业提交名单范围内，暂时无需提交毕业作业
        </p>
      </div>
      <div class="on-graduat-wrap pt-20 d-flex col align-center justify-center" v-else>
        <div class="mb-20 d-flex justify-center align-center">
          <p class="graduat-zy-title fs-20 fw-bold text-center">{{ gradeInfo.className }}毕业考试</p>
        </div>
        <a-button type="primary">提交毕业作业</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'GraduatEmpty',
  components: { },
  data() {
    return {
      gradeInfo: {}
    }
  },
  mounted() {},
  methods: {}
}
</script>
