<template>
  <div class="paper-submit">
    <div class="desc">{{ content.remark }}</div>
    <a-button type="primary" v-if="content.sjIsOnTime === 'Y'" @click="submit">立即提交{{ content.thesisType | setTitle }}</a-button>
  </div>
</template>

<script>
export default {
  name: 'SubmitPaper',
  props: {
    content: { type: Object, default: () => {} }
  },
  components: {},
  data() {
    return {
      gradeInfo: {}
    }
  },
  filters: {
    setTitle(val) {
      let text = '论文'
      switch (val) {
        case '1':
          text = '论文'
          break
        case '2':
          text = '实践'
          break
      }
      return text
    }
  },
  mounted() {},
  methods: {
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.paper-submit {
  text-align: center;
  .desc {
    margin-bottom: 30px;
  }
}
</style>
