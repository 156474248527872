<template>
  <a-modal
    :title="thesisType === '1' ? '上传毕业论文' : '上传社会实践'"
    okText="确认上传"
    :maskClosable="false"
    :visible="showUpload"
    :confirm-loading="uoloadLoading"
    @ok="save"
    @cancel="cancel"
  >
    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
      <a-form-model-item :label="thesisType === '1' ? '论文标题' : '实践标题'" prop="thesisTitle">
        <a-input v-model.trim="ruleForm.thesisTitle" />
      </a-form-model-item>
      <a-form-model-item :label="thesisType === '1' ? '论文附件' : '实践附件'" prop="filePath">
        <div>{{ remark }}</div>
        <a-upload name="file" :customRequest="handleUpload" :file-list="fileList" :beforeUpload="handleBeforeUpload">
          <a-button> <a-icon type="upload" />上传{{ thesisType | setTitle }}</a-button>
        </a-upload>
        <div class="uploadtxt" v-if="thesisType === '1'">注：毕业论文仅允许上传word、zip文件</div>
      </a-form-model-item>
      <a-form-model-item label="电子签名" prop="studentAutographImg">
        <img v-if="ruleForm.studentAutographImg" width="150" :src="ruleForm.studentAutographImg" />
        <span v-else>暂无电子签名</span>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import helper from '@/utils/helper'
import api from '@/api'
export default {
  name: 'UploadJob',
  data() {
    return {
      showUpload: false,
      uoloadLoading: false,
      thesisType: '',
      fileType: '',
      remark: '',
      ruleForm: {
        thesisTitle: '',
        filePath: '',
        fileName: '',
        studentAutographImg: '',
        thesisStudentId: ''
      },
      rules: {
        thesisTitle: [{ required: true, message: '请填写标题' }],
        filePath: [{ required: true, message: '请上传附件' }]
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      },
      fileList: [],
    }
  },
  filters: {
    setTitle(val) {
        let text = '论文'
        switch (val) {
            case '1':
                text = '论文'
                break
            case '2':
                text = '实践'
                break
        }
        return text
    },
  },
  methods: {
    open(data) {
      if (data) {
        this.ruleForm.thesisStudentId = data.thesisStudentId
        this.ruleForm.thesisTitle = data.thesisTitle
        this.ruleForm.studentAutographImg = data.studentAutographImg
        this.remark = data.remark
        this.thesisType = data.thesisType
        this.fileType = data.fileType
      }
      this.toggle()
    },
    toggle(flag = true) {
      this.showUpload = flag
    },
    cancel() {
      this.ruleForm = {
        thesisTitle: '',
        filePath: '',
        fileName: '',
        studentAutographImg: '',
        thesisStudentId: ''
      }
      this.fileList = []
      this.toggle(false)
    },
    save() {
      this.submitForm('ruleForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            this.uoloadLoading = true
            await api.student.submitWork(this.ruleForm)
            this.uoloadLoading = false
            this.cancel()
            this.$message.info('提交成功')
          } catch (e) {
            this.uoloadLoading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async handleUpload(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('studId', helper.getUserInfo('userId'))
      const { data } = await api.student.uploadWork(formData)
      console.log(data)
      this.ruleForm.filePath = data.url
      this.ruleForm.fileName = data.fileName
      this.fileList = [
        {
          uid: file.file.uid,
          name: file.file.name,
          status: 'done',
          url: data
        }
      ]
    },
    handleBeforeUpload(file) {
        //获取文件后缀
        const fileFormat = file.name.substring(file.name.lastIndexOf(".")+1)
        if (this.thesisType == '1' ) {
            if (fileFormat != 'docx' && fileFormat != 'doc' && fileFormat != 'zip') {
                this.$message.error('请上传正确的文件格式！');
                return false;
            }
        }
        if (this.thesisType == '2') {
            const fileTypes = this.fileType.split(',');
            if (!fileTypes.includes(fileFormat)) {
                this.$message.error('请上传正确的文件格式！');
                return false;
            }
        }
    },
  }
}
</script>
<style lang="scss">
  .uploadtxt{
    text-align: left;
    line-height: 2;
    margin-top: 20px;
    font-size: 8px;
    color: #fb841a;
  }
</style>