var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-graduat"},[_c('div',{staticClass:"mb-10"},[_c('a-card',{staticClass:"cus-card no-border",attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('div',{staticClass:"section-header d-flex align-center justify-between flex-wrap"},[_c('h2',{staticClass:"base-title fs-18"}),_c('p',{staticClass:"fs-12 color-blue link",on:{"click":function($event){return _vm.$router.push({ name: 'student_my_grade' })}}},[_vm._v("查看成绩详情")])])]),_c('a-row',{attrs:{"gutter":34}},[_c('a-col',{attrs:{"md":{ span: 8 },"xs":{ span: 24 }}},[_c('progress-bar',{attrs:{"options":{
              theme: '#ad3afc',
              title: '最低毕业学分',
              url: 'process-icon-zdxf',
              minNum: parseInt(_vm.gradeInfo.lowestCredit) || 0,
              size: '64px'
            }}})],1),_c('a-col',{attrs:{"md":{ span: 8 },"xs":{ span: 24 }}},[_c('progress-bar',{attrs:{"options":{
              theme: '#fc9038',
              title: '已获得学分',
              url: 'process-icon-xf',
              denom: _vm.gradeInfo.courseCredit || 0,
              num: _vm.gradeInfo.obtainCredit || 0,
              size: '64px'
            }}})],1),_c('a-col',{attrs:{"md":{ span: 8 },"xs":{ span: 24 }}},[_c('progress-bar',{attrs:{"options":{
              theme: '#4071fb',
              title: '毕业条件',
              url: 'process-icon-tj',
              minNum: _vm.gradeInfo.graduation || '未满足',
              size: '64px'
            }}})],1)],1)],2)],1),_c('div',{staticClass:"mb-10"},[_c('a-card',{staticClass:"no-border",attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('a-row',{staticClass:"pb-10"},[_c('a-col',{attrs:{"md":{ span: 8 },"xs":{ span: 24 }}},[_c('a-select',{staticClass:"cus-select",attrs:{"placeholder":"选择类别"},on:{"select":_vm.planChange},model:{value:(_vm.graPlanId),callback:function ($$v) {_vm.graPlanId=$$v},expression:"graPlanId"}},_vm._l((_vm.planList),function(plan){return _c('a-select-option',{key:plan.graPlanId,attrs:{"value":plan.graPlanId}},[_vm._v(" "+_vm._s(plan.graPlanName)+" ")])}),1)],1)],1),_c('div',{staticClass:"section-header pt-10 dec d-flex align-center justify-between flex-wrap"},[_c('h2',{staticClass:"base-title fs-18"},[_vm._v("本学期毕业时间安排")]),_c('p',{staticClass:"fs-14 color-gray link"})])],1),_c('graduat-table',{attrs:{"content":_vm.currentPlant}})],2)],1),_c('graduat-data',{attrs:{"content":_vm.graduatData},on:{"removeGradImgUrl":_vm.removeGradImgUrl}}),_c('graduat-job',{attrs:{"content":_vm.workList,"id":"graduatJob"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }