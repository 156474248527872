<!--
 * @Author: xiaziwen
 * @Date: 2021-01-24 15:55:22
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-24 16:24:55
 * @FilePath: \crm-education\src\views\student\my_graduat\index.vue
-->
<template>
  <div class="my-graduat">
    <div class="mb-10">
      <a-card class="cus-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18"></h2>
            <p class="fs-12 color-blue link" @click="$router.push({ name: 'student_my_grade' })">查看成绩详情</p>
          </div>
        </template>
        <a-row :gutter="34">
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#ad3afc',
                title: '最低毕业学分',
                url: 'process-icon-zdxf',
                minNum: parseInt(gradeInfo.lowestCredit) || 0,
                size: '64px'
              }"
            ></progress-bar>
          </a-col>
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#fc9038',
                title: '已获得学分',
                url: 'process-icon-xf',
                denom: gradeInfo.courseCredit || 0,
                num: gradeInfo.obtainCredit || 0,
                size: '64px'
              }"
            ></progress-bar>
          </a-col>
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#4071fb',
                title: '毕业条件',
                url: 'process-icon-tj',
                minNum: gradeInfo.graduation || '未满足',
                size: '64px'
              }"
            ></progress-bar>
          </a-col>
        </a-row>
      </a-card>
    </div>
    <!-- <graduat-empty></graduat-empty> -->
    <div class="mb-10">
      <a-card class="no-border" :bordered="false">
        <template slot="title">
          <a-row class="pb-10">
            <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
              <a-select class="cus-select" v-model="graPlanId" @select="planChange" placeholder="选择类别">
                <a-select-option v-for="plan in planList" :value="plan.graPlanId" :key="plan.graPlanId">
                  {{ plan.graPlanName }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">本学期毕业时间安排</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <graduat-table :content="currentPlant"></graduat-table>
      </a-card>
    </div>
    <graduat-data :content="graduatData" @removeGradImgUrl="removeGradImgUrl"></graduat-data>
    <graduat-job :content="workList" id="graduatJob"></graduat-job>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import ProgressBar from '@/components/business/progressbar'
// import GraduatPlan from './c/GraduatPlan'
import GraduatTable from './c/GraduatTable'
import GraduatData from './c/GraduatData'
import GraduatJob from './c/GraduatJob'
import GraduatEmpty from './c/GraduatEmpty'
import api from '@/api'
export default {
  name: 'student_graduat',
  components: {
    ProgressBar,
    // GraduatPlan,
    GraduatTable,
    GraduatData,
    GraduatJob,
    GraduatEmpty
  },
  data() {
    return {
      graPlanId: '',
      gradeInfo: {},
      currentPlant: {},
      planList: [],
      workList: [],
      graduatData: {},
    }
  },
  mounted() {
    this.init()
    this.roll(this.$route.params.isRoll);
  },
  methods: {
    async init() {
      this.getGraduatData()
      await this.getPlan()
      this.initGrade()
      this.getWork()
    },
    planChange() {
      this.matchCurrentPlan(this.planList)
      this.initGrade()
      this.getWork()
    },
    async initGrade() {
      let res = await api.student.getGrade()
      if (res.code === 200) {
        this.gradeInfo = res.data
      }
    },
    async getPlan() {
      let res = await api.student.graduationPlan({ graPlanId: this.graPlanId })
      if (res.code === 200) {
        this.planList = res.data
        this.graPlanId = res.data[0].graPlanId
        this.matchCurrentPlan(res.data)
      }
    },
    matchCurrentPlan(planList = []) {
      const current = planList.filter(ele => ele.graPlanId === this.graPlanId)
      this.currentPlant = current[0] || {}
    },
    async getWork() {
      let res = await api.student.workList({ graPlanId: this.graPlanId })
      if (res.code === 200) {
        this.workList = res.data
      }
    },
    async getGraduatData() {
        let organizationId = localStorage.getItem('organizationId')
        let res = await api.student.getGraduatData({organizationId : organizationId  })
        if (res.code === 200) {
            this.graduatData = res.data
            if (this.graduatData.gradImgState == '2') {
                this.$message.info("您的学历照片已审核通过，请尽快到学信网核对。网址：https://www.chsi.com.cn/，如有疑问请尽快与您的老师联系！")
            }
        }
    },
    removeGradImgUrl(){
      this.graduatData.gradImg = null;
      this.graduatData.gradImgState = 0;
    },

    roll(roll = false){
      if(roll){
        setTimeout(()=>{
          document.querySelector('#graduatJob').scrollIntoView(true)
        },100)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.my-graduat {
  .un-graduat-wrap {
    min-height: 300px;
  }
  .on-graduat-wrap {
    min-height: 300px;
    .graduat-zy-title {
      position: relative;
      padding-left: 30px;
      line-height: 30px;
      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 30px;
        background: url('../../../assets/img/graduat-icon.png') no-repeat center center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
