<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 10:47:06
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:02:07
 * @FilePath: \crm-education\src\views\student\my_exam\c\ExamTimeTable.vue
-->
<template>
  <div class="mb-10" v-if="content.studProveId">
    <a-card class="no-border" :bordered="false">
      <template slot="title">
        <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
          <h2 class="base-title fs-18">毕业资料</h2>
          <p class="fs-14 color-gray link"></p>
        </div>
        <div class="attention">
          <span>注意：</span><br>
          <span>毕业生登记表务必在第三学期完成打印填写并邮寄给班主任。</span><br>
          <span>请确认平台学籍信息和学信网注册信息是否一致，有问题请及时联系相关老师，以免影响毕业！</span><br>
          <span>平台学籍信息查看方式：左侧菜单栏-我的学籍或点击列表内去查看。</span>
        </div>
      </template>
      <table class="cus-special-table">
        <tr>
          <td class="label">毕业资料</td>
          <td class="label">资料状态</td>
          <td class="label">操作</td>
        </tr>
        <tr v-if = "content.isShowRegister =='Y'">
          <td class="content">毕业生登记表</td>
          <td :class="{
                        error: content.isGradRegister !== '1',
                        pass: content.isGradRegister  === '1'
                      }">{{ content.isGradRegister === '1' ?  '已下载' : '未下载'}}</td>
          <td class="content">
            <a-button class="cus-button" type="primary" @click="goShow()">去查看</a-button>
            <a-modal
                    v-model="registerInfoVisible"
                    title="下载毕业生登记表"
                    @ok="handlRegisterInfoOk(content)"
            >
              <div class="confirm-attention">
                <span>说明：</span><br>
                <span>{{ content.remark }}</span>
              </div>
              <p style="text-align: center"><a :href="content.fileUrl">毕业生登记表及样例.zip</a></p>
            </a-modal>
          </td>
        </tr>
        <tr>
          <td class="content">确认学籍信息
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>提示：</span><br>
                <span>查看学信网学籍注册信息：<a href="https://my.chsi.com.cn/archive/index.jsp" target="_blank">https://my.chsi.com.cn/archive/index.jsp</a> </span>
              </template>
              <a-icon type="info-circle" theme="twoTone"/>
            </a-tooltip>
          </td>
          <td :class="{
                        error: content.proveConfirm !== '1',
                        pass: content.proveConfirm  === '1'
                      }">{{ content.proveConfirm === '1' ?  '已确认' : '待确认'}}</td>
          <td class="content">
            <a-button class="cus-button" type="primary" @click="goMySchoolRole()">去查看</a-button>
            <a-button v-if="content.proveConfirm != '1'" style="margin-left: 10px;" class="cus-button" type="primary" @click="showConfirmInfo()">确认信息</a-button>
            <a-modal
                    v-model="confirmInfoVisible"
                    title="确认学籍信息"
                    @ok="handleConfirmInfoOk(content)"
            >
              <div class="confirm-attention">
                <span>注意：</span><br>
                <span>如平台学籍信息与学信网注册信息不一致请及时联系相关老师，点击确认后因学籍信息错误而产生的问题由学生个人负责。</span>
              </div>
              <p style="text-align: center">确认平台学籍信息与学信网注册信息一致？</p>
            </a-modal>
          </td>
        </tr>
        <tr>
          <td class="content">学信网学历照片代采集</td>
          <td :class="{
                        error: content.gradImgState !== '2',
                        pass: content.gradImgState  === '2'
                      }" style="width: 300px">
            {{ content.gradImgState | setGradImgStateText}}
            <div v-if="content.gradImgState === '3'">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{content.gradImgMsg}}</span>
                </template>
                原因： {{content.gradImgMsg | setPartMsg}}
              </a-tooltip>
            </div>
          </td>
          <td class="content">
            <a-button class="cus-button" type="primary" @click="upload(content)" v-if="!content.gradImg">上传</a-button>
            <a-button class="cus-button" type="primary" @click="upload(content)" v-else-if="content.gradImgState =='3' ">重新上传</a-button>
            <a-button class="cus-button" type="primary" @click="upload(content)" v-else>去查看</a-button>
            <a-modal
                    v-model="uploadGradImgVisible"
                    title="学信网学历照片代采集"
                    @ok="handleuploadGradImgOk(content)"
            >
              <div class="gradImg-msg">
                电子毕业照和采集码都为必填<br/>
                电子毕业照<span class="bule">格式要求</span>： <br/>
                1. <span class="bule">蓝色背景</span>，图像大小在<span class="bule">30K-100K</span>之间，格式为jpg<br/>
                2. 若使用PS、美图等软件裁剪图片，<span class="bule">必须在原有照片缩放比例基础上，以4:3比例进行裁剪，</span>以免照片变形，严重影响毕业证发放与使用<br/>
                3. 图像“高”必须为<span class="bule">640（px）</span>，“宽”必须为<span class="bule">480（px）</span>，分辨率最小为<span class="bule">72 dpi</span>（像素/英寸）<br/>
                4. 鉴于毕业照片的严肃性，请同学们务必上传标准证件照，生活照、手机自拍照等将不予审核通过<br/>
              </div>
              <div v-if="isShowPayImg" style="color: red;margin-top: 10px;">
                <span>注：请用手机扫描右侧二维码</span><br/>
                <span style="font-size: 18px" >先在备注栏填好自己的姓名！再支付电子毕业照的费用45元</span>
              </div>
              <a-upload
                      class="student-gradImg"
                      list-type="picture-card"
                      :customRequest="handleUpload"
                      :file-list="fileList"
                      @preview="handlePreview"
                      :beforeUpload="handleBeforeUpload"
                      :remove="removePicture"
                      :showUploadList="{showRemoveIcon:isShowUploadAndDelete}"
              >
                <div v-if="isShowUploadAndDelete">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    点击上传电子毕业照
                  </div>
                </div>
              </a-upload>
              <a-upload
                      class="student-gradImg"
                      list-type="picture-card"
                      :customRequest="handleUploadGradImgCode"
                      :file-list="fileCodeList"
                      @preview="handlePreview"
                      :beforeUpload="handleBeforeUpload"
                      :remove="removePictureCode"
                      :showUploadList="{showRemoveIcon:isShowUploadAndDelete}"
              >
                <div v-if="isShowUploadAndDelete">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    点击上传采集码
                  </div>
                </div>
              </a-upload>
              <img :src="payImgUrl" alt="" class="pay-img" v-if="isShowPayImg" />
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%;" :src="previewImage" />
              </a-modal>
            </a-modal>
          </td>
        </tr>
      </table>
    </a-card>
  </div>
</template>

<script>
import helper from '@/utils/helper'
import api from '@/api'
function getGradImgBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}
export default {
  name: 'GraduatTable',
  props: {
    content: { type: Object, default: () => {} },

  },
  data() {
      return {
          registerInfoVisible: false,
          confirmInfoVisible: false,
          uploadGradImgVisible: false,
          previewVisible: false,
          previewImage: '',
          fileList: [],
          isShowPayImg: false,
          isShowUploadAndDelete:true,
          payImgUrl:'',
          fileCodeList: [],
      }
  },
  computed: {
  },
  mounted() {
  },
  filters: {
      setGradImgStateText(val) {
          let text = '未上传'
          switch (val) {
              case '1':
                  text = '已上传待审核'
                  break
              case '2':
                  text = '审核通过'
                  break
              case '3':
                  text = '审核不通过'
                  break
          }
          return text
      },
      setPartMsg(val) {
          console.log(val.length)
          if (val && val.length > 14) {
              return val.substr(0,14) + "..."
          }
          return val;
      }
  },
  methods: {
      async goShow() {
          this.registerInfoVisible = true
      },
      async handlRegisterInfoOk(content) {
          this.registerInfoVisible = false;
          this.$confirm({
              title: '您是否已经完成毕业生登记表的下载？',
              content: '',
              okText: '是',
              cancelText: '否',
              onOk() {
                  let res = api.student.confirmGradRegister(content)
                  if (res.code === 200){
                      location.reload();
                  }
              },
              onCancel() {
              },
          });
      },

      /*跳入我的学籍页面*/
      async goMySchoolRole() {
          this.$router.replace("/student/my_school_role")
      },
      async showConfirmInfo() {
          this.confirmInfoVisible = true;
      },
      async handleConfirmInfoOk(content) {
          let res = await api.student.updateProveConfirm(content)
          if (res.code === 200){
              this.confirmInfoVisible = false;
              this.$message.info("确认成功");
              location.reload();
          } else {
              this.$message.error(res.msg)
          }
      },
      async upload(content) {
          let res = await api.student.isUploadGradImg({ organizationId: localStorage.getItem('organizationId') })
          if(res.code ===400){
            this.$message.error(res.msg);
            return;
          }

          this.showPayImg(res);

          this.uploadGradImgVisible = true;
          if(content.gradImgState=="1" ||content.gradImgState =="2"){
            this.isShowUploadAndDelete = false;
          }
          if (content.gradImg) {
              this.fileList = [{
                      uid: Math.random() * 1000000000,
                      name: 'avatar',
                      status: 'done',
                      url: content.gradImg
                  }]
          }
          if (content.gradImgCode) {
              this.fileCodeList = [{
                  uid: Math.random() * 1000000000,
                  name: 'avatar',
                  status: 'done',
                  url: content.gradImgCode
              }]
          }
      },
      async handleUpload(file) {
          const formData = new FormData()
          formData.append('file', file.file)
          formData.append('studId', helper.getUserInfo('userId'))
          const { data } = await api.student.uploadStudentGradImg(formData)
          this.fileList = [
              {
                  uid: file.file.uid,
                  name: file.file.name,
                  status: 'done',
                  url: data
              }
          ]
      },
      async handlePreview(file) {
          if (!file.url && !file.preview) {
              file.preview = await getGradImgBase64(file.originFileObj)
          }
          this.previewImage = file.url || file.preview
          this.previewVisible = true
      },
      handleCancel() {
          this.previewVisible = false;
      },
      /**
       * 上传的前置钩子，校验文件类型
       */
      handleBeforeUpload(file) {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === "image/png";
          if (!isJpgOrPng) {
              this.$message.error('请上传正确的照片格式！');
              return false;
          }
          const isLt2M = 30 < file.size / 1024  < 900;
          if (!isLt2M) {
              this.$message.error('请上传正确大小的照片！');
              return false;
          }
      },
      /**
       * 保存上传地址
       */
      async handleuploadGradImgOk(content) {
          if (this.fileList.length <= 0) {
              this.$message.error('请上传毕业电子照')
              return;
          }
          if (this.fileCodeList.length <= 0) {
              this.$message.error('请上传采集码')
              return;
          }
          let res = await api.student.updateGradImg({
              studProveId: content.studProveId,
              gradImg: this.fileList[0].url,
              gradImgCode: this.fileCodeList[0].url
          })
          if (res.code === 200){
              this.uploadGradImgVisible = false;
              this.$message.info("保存成功");
              location.reload();
          } else {
              this.$message.error(res.msg)
          }
      },
      showPayImg(res) {
          /*let organizationId = localStorage.getItem('organizationId');
          if (organizationId === '100000') {
              this.isShowPayImg = true;
          }*/
          if(res.data){
            this.isShowPayImg = true;
            this.payImgUrl = res.data;
          }else{
            this.isShowPayImg = false;
            this.payImgUrl = '';
          }
      },
    removePicture(){
        this.removeImage('img')
    },
    removePictureCode(){
        this.removeImage('imgCode')
    },
    removeImage(type) {
        const _this = this;
        this.$confirm({
            title: '是否确定要删除？',
            okText: '是',
            cancelText: '否',
            onOk() {
                api.student.removeStudentGradImg({studProveId:_this.content.studProveId,removeType: type}).then(res =>{
                    if(res.code === 200 ){
                        console.log(1)
                        if ('img' == type){
                            _this.fileList = [];
                        } else {
                            _this.fileCodeList = [];
                        }
                        //更新父组件this.content.gradImg
                        _this.$emit('removeGradImgUrl');
                        return true;
                    }
                });
            },
            onCancel() {
            },
        });
    },
    async handleUploadGradImgCode(file) {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('studId', helper.getUserInfo('userId'))
        const { data } = await api.student.uploadStudentGradImgCode(formData)
        this.fileCodeList = [{
                uid: file.file.uid,
                name: file.file.name,
                status: 'done',
                url: data
            }]
    },
  }
}
</script>

<style lang="scss" scoped>
.cus-special-table {
  border-collapse: collapse;
  width: 100%;
  td {
    border: 1px solid #d7dcdc;
    padding: 15px 10px;
    &.label {
      background-color: #eff2fb;
    }
  }
}
  .attention {
    color: red;
    position: relative;
    top: 10px;
  }
  .confirm-attention {
    color: red;
    position: relative;
    top: -20px;
    font-size: small;
  }
  .student-gradImg {
    margin-top: 20px;
    margin-left: 50px;
  }
  .gradImg-msg {
    background-color: #ffebcd;
    color: #fb841a ;
    border: 1px solid #faad14;
  }
  .pay-img {
    width: 30%;
    margin-left: 300px;
    margin-top: -215px;
  }
  .bule {
    color: #0f7ef1;
  }
  .content {
    text-align: center;
  }
  .label {
    text-align: center;
    background-color: #eff2fb;
  }
  .error {
    color: #fb841a;
    text-align: center;
  }
  .pass {
    color: #385dfb;
    text-align: center;
  }
</style>
