<template>
  <a-modal
    title="详情"
    :visible="recordShow"
    width="700px"
    :footer="null"
    :maskClosable="false"
    @ok="save"
    @cancel="toggle(false)"
  >
    <div class="job-list mb-20">
      <table class="job-info">
        <colgroup>
          <col width="30%" />
          <col width="50%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <tr>
            <th colspan="5">{{ content.subjectName || '暂无' }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="g-course-info">
                <div class="info-i">
                  <span class="label">对应课程：</span><span class="value">{{ content.courseName || '无' }}</span>
                </div>
                <div class="info-i">
                  <span class="label">课程代码：</span><span class="value">{{ content.courseCode || '无' }}</span>
                </div>
                <div class="info-i">
                  <span class="label">对应专业：</span><span class="value">{{ content.majorName || '无' }}</span>
                </div>
                <div class="info-i">
                  <span class="label">专业层次：</span
                  ><span class="value">{{ content.majorLevel === '1' ? '专科' : '本科' }}</span>
                </div>
                <div class="info-i">
                  <span class="label">指导老师：</span><span class="value">{{ content.realname || '无' }}</span>
                </div>
                <div class="info-i">
                  <span class="label">联系方式：</span><span class="value">{{ content.phone || '无' }}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="g-cell">
                <div class="result">
                  <div class="doc" v-for="(item, index) in content.objectFile" :key="index">
                    <a target="_blank" :href="item.objectFileUrl">{{ item.objectFileName }}</a>
                    <a style="margin-left: 10px" target="_blank" :href="getFileUrl(item.objectFileUrl)"><a-icon type="file-text" /></a>
                  </div>
                </div>
                <div class="txt">{{ content.thesisType | setTitle }}标题</div>
              </div>
            </td>
            <td>
              <div class="g-cell">
                <div
                  :class="{
                    result: true,
                    waitSubmit: content.thesisState === '1',
                    waitCheck: content.thesisState === '2',
                    error: content.thesisState === '3',
                    pass: content.thesisState === '4'
                  }"
                >
                  {{ content.thesisState | status }}
                </div>
                <div class="txt">{{ content.thesisType | setTitle }}状态</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paper-record-box">
      <div class="paper-title fs-18">{{ content.thesisType | setTitle }}记录</div>
      <submit-paper v-if="content.thesisState === '1'" :content="content" @submit="handleSubmit"></submit-paper>
      <submit-time-line v-else :content="content" @submit="handleSubmit"></submit-time-line>
    </div>
  </a-modal>
</template>
<script>
import SubmitTimeLine from './SubmitTimeLine'
import SubmitPaper from './SubmitPaper'
export default {
  name: 'TimeLine',
  components: { SubmitTimeLine, SubmitPaper },
  data() {
    return {
      recordShow: false,
      content: {}
    }
  },
  filters: {
    setTitle(val) {
      let text = '论文'
      switch (val) {
        case '1':
          text = '论文'
          break
        case '2':
          text = '实践'
          break
      }
      return text
    },
    status(val) {
      //  1待提交 2待评审 3评审未通过 4评审通过 5已通过，无需提交",
      let text = '--'
      switch (val) {
        case '0':
            text = '重新提交'
            break
        case '1':
          text = '待提交'
          break
        case '2':
          text = '待评审'
          break
        case '3':
          text = '评审未通过'
          break
        case '4':
          text = '评审通过'
          break
        case '5':
          text = '已通过，无需提交'
          break
      }
      return text
    }
  },
  methods: {
    open(data) {
      if (data) {
        this.content = data
      }
      this.toggle()
    },
    toggle(flag = true) {
      this.recordShow = flag
    },
    save() {},
    handleSubmit() {
      this.toggle(false)
      this.$emit('submit')
    },
    getFileUrl(url){
      return 'http://ow365.cn/?info=3&i=25157&furl='+url;
    }
  }
}
</script>
<style lang="scss">
.paper-record-box {
  .paper-title {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin: 20px 0;
  }
}
</style>
