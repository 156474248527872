<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 10:47:06
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:02:07
 * @FilePath: \crm-education\src\views\student\my_exam\c\ExamTimeTable.vue
-->
<template>
  <table class="cus-special-table">
    <tr>
      <!-- <td class="label">申请时间</td>
      <td class="content">{{ content.applyStime }}——{{ content.applyEtime }}</td> -->
      <td class="label">社会实践提交时间</td>
      <td class="content">{{ content.socialPracticeStime }}——{{ content.socialPracticeEtime }}</td>
       <td class="label">毕业论文提交时间</td>
      <td class="content">{{ content.thesisStime }}——{{ content.thesisEtime }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'GraduatTable',
  props: {
    content: { type: Object, default: () => {} }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.cus-special-table {
  border-collapse: collapse;
  width: 100%;
  td {
    border: 1px solid #d7dcdc;
    padding: 15px 10px;
    &.label {
      background-color: #eff2fb;
    }
  }
}
</style>
