<template>
  <div class="mb-10">
    <a-card class="no-border" :bordered="false">
      <template slot="title">
        <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
          <h2 class="base-title fs-18">毕业作业</h2>
          <p class="fs-14 color-gray link"></p>
        </div>
      </template>
      <div class="graduat-job" v-if="content.length">
        <div class="job-list mb-20" v-for="(item, index) in content" :key="index">
          <table class="job-info">
            <colgroup>
              <col width="20%" />
              <col width="40%" />
              <col width="10%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="5">{{ item.subjectName || '暂无' }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="g-course-info">
                    <div class="info-i">
                      <span class="label">对应课程：</span><span class="value">{{ item.courseName || '无' }}</span>
                    </div>
                    <div class="info-i">
                      <span class="label">课程代码：</span><span class="value">{{ item.courseCode || '无' }}</span>
                    </div>
                    <div class="info-i">
                      <span class="label">对应专业：</span><span class="value">{{ item.majorName || '无' }}</span>
                    </div>
                    <div class="info-i">
                      <span class="label">专业层次：</span
                      ><span class="value">{{ item.majorLevel === '1' ? '专科' : '本科' }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="g-cell">
                    <div class="result">{{ item.thesisTitle || '暂无' }}</div>
                    <div class="txt">{{ item.thesisType | setTitle }}标题</div>
                  </div>
                </td>
                <td>
                  <div class="g-cell">
                    <div class="result">{{ item.thesisScore || '--' }}</div>
                    <div class="txt">初评得分</div>
                  </div>
                </td>
                <td>
                  <div class="g-cell">
                    <div
                      :class="{
                        result: true,
                        waitSubmit: item.thesisState === '1'||item.thesisState === '0',
                        waitCheck: item.thesisState === '2',
                        error: item.thesisState === '3',
                        pass: item.thesisState === '4'
                      }"
                    >
                      {{ item.thesisState | status }}
                    </div>
                    <div class="txt">{{ item.thesisType | setTitle }}状态</div>
                  </div>
                </td>
                <td>
                  <div class="g-action">
                    <!--<div v-if="item.sjIsOnTime === 'N'" class="txt disabled">不在范围内</div>-->
                    <!-- <a-tooltip placement="top" v-else-if="item.thesisState === '1'">
                      <template slot="title">
                        <span>{{ item.remark }}</span>
                      </template>
                      <a-button type="primary" @click="upload(item)">立即提交论文</a-button>
                    </a-tooltip> -->
                    <a-button type="primary" @click="reviewRecord(item)">去查看</a-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <paper-records></paper-records> -->
        </div>
      </div>
      <a-empty v-else />
    </a-card>
    <time-line ref="timeLine" @submit="upload"></time-line>
    <upload-job ref="uploadJob"></upload-job>
  </div>
</template>

<script>
// import PaperRecords from './PaperRecords'
import UploadJob from './UploadJob'
import TimeLine from './TimeLine'
import api from '@/api'
export default {
  name: 'GraduatJob',
  components: { UploadJob, TimeLine },
  props: {
    content: { type: Array, default: () => [] }
  },
  filters: {
    setTitle(val) {
      let text = '论文'
      switch (val) {
        case '1':
          text = '论文'
          break
        case '2':
          text = '实践'
          break
      }
      return text
    },
    status(val) {
      //  1待提交 2待评审 3评审未通过 4评审通过 5已通过，无需提交",
      let text = '--'
      switch (val) {
        case '0':
            text = '请重新提交'
            break
        case '1':
          text = '待提交'
          break
        case '2':
          text = '待评审'
          break
        case '3':
          text = '评审未通过'
          break
        case '4':
          text = '评审通过'
          break
        case '5':
          text = '已通过，无需提交'
          break
      }
      return text
    }
  },
  data() {
    return {
      info: {}
    }
  },
  computed: {},
  mounted() {},
  methods: {
    async getDetail() {
      const { data = {} } = await api.student.workDetail({ thesisStudentId: this.thesisStudentId })
      return data
    },
    async upload() {
      const data = await this.getDetail()
      this.$refs['uploadJob'].open(data[0] || {})
    },
    async reviewRecord(item) {
      this.thesisStudentId = item.thesisStudentId
      const data = await this.getDetail()
      const val =  data[0] || {}
      const content = {...val,sjIsOnTime: item.sjIsOnTime}
      this.$refs['timeLine'].open(content)
    }
  }
}
</script>

<style lang="scss">
.job-list {
  .job-info {
    width: 100%;
    border-collapse: collapse;
    thead {
      background: #385dfb;
      color: #fff;
      th {
        font-size: 18px;
        text-align: center;
        height: 50px;
      }
    }
    tbody {
      tr {
        td {
          text-align: center;
          border: 1px solid #d7dcdc;
          padding: 5px 5px;
        }
      }
    }
  }
}
.g-course-info {
  text-align: left;
  .info-i {
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    .value {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.g-cell {
  .result {
    font-weight: bold;
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &.waitSubmit {
      color: #1890ff;
    }
    &.waitCheck {
      color: #385dfb;
    }
    &.error {
      color: #fb4f27;
    }
    &.pass {
      color: green;
    }
    .doc{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 326px;
      font-size: 14px;
    }
  }
}
.g-action {
  .disabled {
    color: #c2bfbf;
  }
}
</style>
